import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./src/assets/css/typography.css";
import "./src/assets/css/global.css";
// import "swiper/css/thumbs";
// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/pagination";

import React from "react";
import ReactDOM from "react-dom/client";

// export const onRouteUpdate = ({ location, prevLocation }) => {
//   // if (location.pathname !== prevLocation?.pathname) {
//   //   window.scrollTo(0, 0);
//   // }
//    if (typeof window !== 'undefined') {
//         window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//     }
// };

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    window.history.scrollRestoration = 'manual';
    const currentPosition = getSavedScrollPosition(location, location.key);
    if (!currentPosition) {
        window.scrollTo(0, 0);
    } else {
        window.setTimeout(() => {
            window.requestAnimationFrame(() => {
                window.scrollTo(...currentPosition);
            });
        }, 0);
    }

    return false;
};